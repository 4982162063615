<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2"> Settings </CDropdownHeader>
      <CDropdownItem class="c-pointer" @click="$router.push('/profile')"><CIcon icon="cil-user" /> Administration</CDropdownItem>
      <!--      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>-->
      <!--      <CDropdownItem> <CIcon icon="cil-file" /> Projects </CDropdownItem>-->
      <CDropdownDivider />
      <CDropdownItem class="custom-hover">
        <CButton type="button" color="dark" style="width: 100%" @click="logoutRequest">Logout</CButton>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/undefined.png'
import { mapActions } from 'vuex'
import router from '@/router'
import Logger from '@/tools/Logger'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),

    logoutRequest() {
      this.logout()
        .then(() => {
          router.push('/login')
        })
        .catch((e) => {
          Logger.log('error signing out: ', e)
        })
    },
  },
}
</script>
<style scoped>
.custom-hover:hover {
  background-color: transparent;
}
</style>
