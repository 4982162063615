<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
:root {
  --amplify-primary-color: #b7b7b7;
}
</style>
