import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader'
import Amplify, { Auth } from 'aws-amplify'
import awsExports from './aws-exports'

Amplify.configure(awsExports)
Auth.configure(awsExports)

applyPolyfills().then(() => {
  defineCustomElements(window)
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)

app.mount('#app')
