import { API } from 'aws-amplify'
import { getRefundByRefundId, listRefundByDate } from '../../graphql/queries'
import Logger from '../../tools/Logger'

const getDefaultState = () => {
  return {
    refunds: [],
    loading: false,
    nextNextToken: '',
    filter: {},
    selectedRefunds: [],
  }
}

const state = getDefaultState()

const getters = {
  getRefunds: (state) => state.refunds,
  isLoading: (state) => state.loading,
  getNextNextToken: (state) => state.nextNextToken,
  getFilter: (state) => state.filter,
  getSelectedRefunds: (state) => state.selectedRefunds,
}

const actions = {
  async getRefundsList({ commit }, { projectId, filter, nextToken, limit }) {
    commit('setLoading', true)
    try {
      const response = await API.graphql({
        query: listRefundByDate,
        variables: {
          projectId,
          ...filter,
          sortDirection: 'DESC',
          nextToken,
          limit,
        },
      })
      Logger.log(response)
      commit('setRefunds', response.data.listRefundByDate.items)
      commit('setLoading', false)
      commit('setNextNextToken', response.data.listRefundByDate.nextToken)
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async getRefundsByParameter({ commit }, { projectId, filter }) {
    // filterExpression - это условное обозначение филтра в данном случае, это не то же самое что filter поле
    const filterExpression = { eq: projectId }

    let query
    let responseQuery

    switch (filter.filterName) {
      case 'refund_id':
        query = getRefundByRefundId
        responseQuery = 'getRefundByRefundId'
        break
      default:
        Logger.log('wrong filter field')
        return {
          message: 'You can filter by refund ID',
          success: false,
        }
    }
    try {
      const response = await API.graphql({
        query,
        variables: {
          [filter.filterName]: filter.filterValue,
          projectId: filterExpression,
        },
      })
      Logger.log(response)
      commit('setRefunds', response.data[`${responseQuery}`].items)
      commit('setNextNextToken', response.data[`${responseQuery}`].nextToken)
      commit('setFilter', { projectId: filterExpression })
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async getRefundsByRange({ commit }, { projectId, filter, limit }) {
    const filterExpression = {
      between: [new Date(filter.startDate + 'Z').toISOString(), new Date(filter.endDate + 'Z').toISOString()],
    }
    Logger.log(filterExpression)
    try {
      const response = await API.graphql({
        query: listRefundByDate,
        variables: {
          projectId,
          createdAt: filterExpression,
          sortDirection: 'DESC',
          limit,
        },
      })
      Logger.log(response)
      commit('setRefunds', response.data.listRefundByDate.items)
      commit('setNextNextToken', response.data.listRefundByDate.nextToken)
      commit('setFilter', { createdAt: filterExpression })
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  addToSelectedRefunds({ commit }, refund) {
    commit('addToSelectedRefunds', refund)
  },
  clearSelectedRefunds({ commit }) {
    commit('clearSelectedRefunds', [])
  },
  clearFilter({ commit }) {
    commit('clearFilter', {})
  },
  clearAllRefunds({ commit }) {
    commit('clearAllRefunds', [])
  },
}

const mutations = {
  setRefunds: (state, refunds) => (state.refunds = refunds),
  setNextNextToken: (state, token) => (state.nextNextToken = token),
  setLoading: (state, bool) => (state.loading = bool),
  setFilter: (state, filter) => (state.filter = filter),
  addToSelectedRefunds: (state, { checked, transaction }) => {
    if (state.selectedRefunds.length > 0) {
      state.selectedRefunds = state.selectedRefunds.filter((p) => p.refund_id !== transaction.refund_id)
      if (checked) state.selectedRefunds = [...state.selectedRefunds, transaction]
    } else {
      state.selectedRefunds = [...state.selectedRefunds, transaction]
    }
  },
  clearSelectedRefunds: (state, payload) => (state.selectedRefunds = payload),
  clearFilter: (state, filter) => (state.filter = filter),
  clearAllRefunds: (state, payload) => (state.refunds = payload),
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
