import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import ModernLayout from '@/layouts/ModernLayout.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/pages/Dashboard.vue'),
      },
      {
        path: '/payments',
        name: 'Payments',
        component: () => import('@/views/pages/Payments.vue'),
      },
      {
        path: '/payouts',
        name: 'Payouts',
        // component: () => (process.env.VUE_APP_PAYOUT ? import('@/views/pages/Payouts.vue') : import('@/views/pages/Page404.vue')),
        component: () => import('@/views/pages/Payouts.vue'),
      },
      {
        path: '/refunds',
        name: 'Refunds',
        component: () => import('@/views/pages/Refunds.vue'),
      },
      {
        path: '/export',
        name: 'Export',
        component: () => import('@/views/pages/Export.vue'),
      },
      {
        path: '/report',
        name: 'Report',
        component: () => import('@/views/pages/Report.vue'),
      },
      {
        path: '/faq',
        name: 'FAQ',
        component: () => import('@/views/pages/FAQ.vue'),
      },
      {
        path: '/support',
        name: 'Support',
        component: () => import('@/views/pages/Support.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/pages/Profile.vue'),
      },
      {
        path: '/wallets',
        name: 'Wallets',
        component: () => import('@/views/pages/Wallets.vue'),
      },
      {
        path: '/wallet/info',
        name: 'Wallet Info',
        component: () => import('@/views/pages/WalletInfo.vue'),
      },
      {
        path: '/profile/project',
        name: 'Project Settings',
        component: () => import('@/views/pages/ProjectSettings.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/pages/Register.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/pages/Page404.vue'),
  },
  {
    path: '/hpp',
    name: 'HPP',
    meta: { layout: ModernLayout },
    props: (route) => ({ query: { ...route.query, baseUrl: process.env.VUE_APP_API_BASE_URL } }),
    component: () => import('@/views/pages/Hpp.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
