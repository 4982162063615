import { sendCallbacks } from '@/graphql/mutations'
import { API } from 'aws-amplify'
import Logger from '../../tools/Logger'

const getDefaultState = () => {
  return {
    loading: false,
  }
}

const state = getDefaultState()

const getters = {
  isCallbackLoading: (state) => state.loading,
}

const actions = {
  async sendCallback({ commit }, data) {
    commit('setLoading', true)
    try {
      const res = await API.graphql({
        query: sendCallbacks,
        variables: { input: data },
      })
      commit('setLoading', false)
      return res.data.sendCallbacks
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
}

const mutations = {
  test: () => {
    alert('test')
  },
  setLoading: (state, loading) => (state.loading = loading),
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
