/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRefund = /* GraphQL */ `
  mutation CreateRefund($input: CreateRefundInput!) {
    createRefund(input: $input) {
      message
      success
      __typename
    }
  }
`;
export const updatePayout = /* GraphQL */ `
  mutation UpdatePayout($input: UpdateStatusInput!) {
    updatePayout(input: $input) {
      message
      result
      success
      __typename
    }
  }
`;
export const prepareDataForCSV = /* GraphQL */ `
  mutation PrepareDataForCSV($input: PrepareDataForCSVInput!) {
    prepareDataForCSV(input: $input) {
      message
      success
      result
      __typename
    }
  }
`;
export const createAllProvidersReport = /* GraphQL */ `
  mutation CreateAllProvidersReport($input: createAllProvidersReportInput!) {
    createAllProvidersReport(input: $input) {
      message
      success
      result
      __typename
    }
  }
`;
export const calculateConversion = /* GraphQL */ `
  mutation CalculateConversion($input: PrepareDataForCSVInput!) {
    calculateConversion(input: $input) {
      message
      success
      result {
        conversion
        errorsDescription
        noneWlPan
        noneWlPanCount
        noneWlPanConversion
        __typename
      }
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      success
      result
      message
      __typename
    }
  }
`;
export const sendCallbacks = /* GraphQL */ `
  mutation SendCallbacks($input: SendCallbacksInput!) {
    sendCallbacks(input: $input) {
      success
      message
      error_callbacks
      success_callbacks_count
      __typename
    }
  }
`;
export const createPaymentLink = /* GraphQL */ `
  mutation CreatePaymentLink($input: CreatePaymentLinkInput!) {
    createPaymentLink(input: $input) {
      success
      result
      message
      __typename
    }
  }
`;
export const confirmUser = /* GraphQL */ `
  mutation ConfirmUser($input: ConfirmUserInput) {
    confirmUser(input: $input) {
      message
      success
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput) {
    deleteUser(input: $input) {
      message
      success
      __typename
    }
  }
`;
export const addUserToGroup = /* GraphQL */ `
  mutation AddUserToGroup($input: AddUserToGroupInput) {
    addUserToGroup(input: $input) {
      message
      success
      __typename
    }
  }
`;
export const getTransactionsPrivateAccess = /* GraphQL */ `
  mutation GetTransactionsPrivateAccess(
    $input: GetTransactionsPrivateAccessInput
  ) {
    getTransactionsPrivateAccess(input: $input) {
      message
      result
      items
      nextToken
      success
      __typename
    }
  }
`;
export const createBalanceOperation = /* GraphQL */ `
  mutation CreateBalanceOperation($input: createBalanceOperationInput) {
    createBalanceOperation(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const activateProject = /* GraphQL */ `
  mutation ActivateProject($input: ActivateProjectInput!) {
    activateProject(input: $input) {
      message
      success
      __typename
    }
  }
`;
export const changeProjectSettings = /* GraphQL */ `
  mutation ChangeProjectSettings($input: ChangeProjectSettingsInput!) {
    changeProjectSettings(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const changeTransactionStatus = /* GraphQL */ `
  mutation ChangeTransactionStatus($input: ChangeTransactionStatusInput!) {
    changeTransactionStatus(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const mfaAuth = /* GraphQL */ `
  mutation MfaAuth($input: MfaAuthorizationInput!) {
    mfaAuth(input: $input) {
      success
      message
      payload
      __typename
    }
  }
`;
export const createPayout = /* GraphQL */ `
  mutation CreatePayout($input: CreatePayoutInput!) {
    createPayout(input: $input) {
      message
      success
      __typename
    }
  }
`;
export const createAmplifyExportReportHelper = /* GraphQL */ `
  mutation CreateAmplifyExportReportHelper(
    $input: CreateAmplifyExportReportHelperInput!
    $condition: ModelAmplifyExportReportHelperConditionInput
  ) {
    createAmplifyExportReportHelper(input: $input, condition: $condition) {
      id
      result
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAmplifyExportReportHelper = /* GraphQL */ `
  mutation UpdateAmplifyExportReportHelper(
    $input: UpdateAmplifyExportReportHelperInput!
    $condition: ModelAmplifyExportReportHelperConditionInput
  ) {
    updateAmplifyExportReportHelper(input: $input, condition: $condition) {
      id
      result
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAmplifyExportReportHelper = /* GraphQL */ `
  mutation DeleteAmplifyExportReportHelper(
    $input: DeleteAmplifyExportReportHelperInput!
    $condition: ModelAmplifyExportReportHelperConditionInput
  ) {
    deleteAmplifyExportReportHelper(input: $input, condition: $condition) {
      id
      result
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAmplifyBalanceOperation = /* GraphQL */ `
  mutation CreateAmplifyBalanceOperation(
    $input: CreateAmplifyBalanceOperationInput!
    $condition: ModelAmplifyBalanceOperationConditionInput
  ) {
    createAmplifyBalanceOperation(input: $input, condition: $condition) {
      id
      project_id
      type
      comment
      amount
      currency
      balance_before
      balance_after
      groups
      created_at
      idByType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAmplifyBalanceOperation = /* GraphQL */ `
  mutation UpdateAmplifyBalanceOperation(
    $input: UpdateAmplifyBalanceOperationInput!
    $condition: ModelAmplifyBalanceOperationConditionInput
  ) {
    updateAmplifyBalanceOperation(input: $input, condition: $condition) {
      id
      project_id
      type
      comment
      amount
      currency
      balance_before
      balance_after
      groups
      created_at
      idByType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAmplifyBalanceOperation = /* GraphQL */ `
  mutation DeleteAmplifyBalanceOperation(
    $input: DeleteAmplifyBalanceOperationInput!
    $condition: ModelAmplifyBalanceOperationConditionInput
  ) {
    deleteAmplifyBalanceOperation(input: $input, condition: $condition) {
      id
      project_id
      type
      comment
      amount
      currency
      balance_before
      balance_after
      groups
      created_at
      idByType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAmplifyGateway = /* GraphQL */ `
  mutation CreateAmplifyGateway(
    $input: CreateAmplifyGatewayInput!
    $condition: ModelAmplifyGatewayConditionInput
  ) {
    createAmplifyGateway(input: $input, condition: $condition) {
      id
      gateway_id
      gateway_name
      gateway_currencies
      gateway_types
      options
      access
      groups
      allowFetchProviderRawData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAmplifyGateway = /* GraphQL */ `
  mutation UpdateAmplifyGateway(
    $input: UpdateAmplifyGatewayInput!
    $condition: ModelAmplifyGatewayConditionInput
  ) {
    updateAmplifyGateway(input: $input, condition: $condition) {
      id
      gateway_id
      gateway_name
      gateway_currencies
      gateway_types
      options
      access
      groups
      allowFetchProviderRawData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAmplifyGateway = /* GraphQL */ `
  mutation DeleteAmplifyGateway(
    $input: DeleteAmplifyGatewayInput!
    $condition: ModelAmplifyGatewayConditionInput
  ) {
    deleteAmplifyGateway(input: $input, condition: $condition) {
      id
      gateway_id
      gateway_name
      gateway_currencies
      gateway_types
      options
      access
      groups
      allowFetchProviderRawData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAmplifyProject = /* GraphQL */ `
  mutation CreateAmplifyProject(
    $input: CreateAmplifyProjectInput!
    $condition: ModelAmplifyProjectConditionInput
  ) {
    createAmplifyProject(input: $input, condition: $condition) {
      id
      active
      email
      groups
      type
      name
      callback_url
      is_callback_id
      payment_gateway
      payment_gateways
      merchant_name
      provider_name
      option_id
      currencies
      private_access_config {
        default_name
        default_merchant_name
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAmplifyProject = /* GraphQL */ `
  mutation UpdateAmplifyProject(
    $input: UpdateAmplifyProjectInput!
    $condition: ModelAmplifyProjectConditionInput
  ) {
    updateAmplifyProject(input: $input, condition: $condition) {
      id
      active
      email
      groups
      type
      name
      callback_url
      is_callback_id
      payment_gateway
      payment_gateways
      merchant_name
      provider_name
      option_id
      currencies
      private_access_config {
        default_name
        default_merchant_name
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAmplifyProject = /* GraphQL */ `
  mutation DeleteAmplifyProject(
    $input: DeleteAmplifyProjectInput!
    $condition: ModelAmplifyProjectConditionInput
  ) {
    deleteAmplifyProject(input: $input, condition: $condition) {
      id
      active
      email
      groups
      type
      name
      callback_url
      is_callback_id
      payment_gateway
      payment_gateways
      merchant_name
      provider_name
      option_id
      currencies
      private_access_config {
        default_name
        default_merchant_name
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAmplifyPayment = /* GraphQL */ `
  mutation CreateAmplifyPayment(
    $input: CreateAmplifyPaymentInput!
    $condition: ModelAmplifyPaymentConditionInput
  ) {
    createAmplifyPayment(input: $input, condition: $condition) {
      id
      payment_id
      order_id
      transaction_type
      payment_system
      projectId
      raw_error_message
      provider_id
      amount
      net_amount
      error_code
      error_description
      card
      currency
      transaction_status
      groups
      createdAt
      updatedAt
      project_currency
      raw_request {
        description
        failure_url
        ip
        project
        result_url
        signature
        success_url
        tokenData {
          country
          expiration_month
          expiration_year
          issuer
          number
          type
          scheme
          __typename
        }
        user_contact_email
        user_name
        user_phone
        user_address
        user_city
        user_state
        user_country
        user_postal_code
        __typename
      }
      __typename
    }
  }
`;
export const updateAmplifyPayment = /* GraphQL */ `
  mutation UpdateAmplifyPayment(
    $input: UpdateAmplifyPaymentInput!
    $condition: ModelAmplifyPaymentConditionInput
  ) {
    updateAmplifyPayment(input: $input, condition: $condition) {
      id
      payment_id
      order_id
      transaction_type
      payment_system
      projectId
      raw_error_message
      provider_id
      amount
      net_amount
      error_code
      error_description
      card
      currency
      transaction_status
      groups
      createdAt
      updatedAt
      project_currency
      raw_request {
        description
        failure_url
        ip
        project
        result_url
        signature
        success_url
        tokenData {
          country
          expiration_month
          expiration_year
          issuer
          number
          type
          scheme
          __typename
        }
        user_contact_email
        user_name
        user_phone
        user_address
        user_city
        user_state
        user_country
        user_postal_code
        __typename
      }
      __typename
    }
  }
`;
export const deleteAmplifyPayment = /* GraphQL */ `
  mutation DeleteAmplifyPayment(
    $input: DeleteAmplifyPaymentInput!
    $condition: ModelAmplifyPaymentConditionInput
  ) {
    deleteAmplifyPayment(input: $input, condition: $condition) {
      id
      payment_id
      order_id
      transaction_type
      payment_system
      projectId
      raw_error_message
      provider_id
      amount
      net_amount
      error_code
      error_description
      card
      currency
      transaction_status
      groups
      createdAt
      updatedAt
      project_currency
      raw_request {
        description
        failure_url
        ip
        project
        result_url
        signature
        success_url
        tokenData {
          country
          expiration_month
          expiration_year
          issuer
          number
          type
          scheme
          __typename
        }
        user_contact_email
        user_name
        user_phone
        user_address
        user_city
        user_state
        user_country
        user_postal_code
        __typename
      }
      __typename
    }
  }
`;
export const createAmplifyPayout = /* GraphQL */ `
  mutation CreateAmplifyPayout(
    $input: CreateAmplifyPayoutInput!
    $condition: ModelAmplifyPayoutConditionInput
  ) {
    createAmplifyPayout(input: $input, condition: $condition) {
      id
      payout_id
      order_id
      transaction_type
      projectId
      provider_id
      raw_error_message
      amount
      full_amount
      amount_gate
      groups
      card
      qiwi
      createdAt
      updatedAt
      currency
      payout_status
      user_name
      user_contact_email
      user_phone
      error_code
      error_message
      __typename
    }
  }
`;
export const updateAmplifyPayout = /* GraphQL */ `
  mutation UpdateAmplifyPayout(
    $input: UpdateAmplifyPayoutInput!
    $condition: ModelAmplifyPayoutConditionInput
  ) {
    updateAmplifyPayout(input: $input, condition: $condition) {
      id
      payout_id
      order_id
      transaction_type
      projectId
      provider_id
      raw_error_message
      amount
      full_amount
      amount_gate
      groups
      card
      qiwi
      createdAt
      updatedAt
      currency
      payout_status
      user_name
      user_contact_email
      user_phone
      error_code
      error_message
      __typename
    }
  }
`;
export const deleteAmplifyPayout = /* GraphQL */ `
  mutation DeleteAmplifyPayout(
    $input: DeleteAmplifyPayoutInput!
    $condition: ModelAmplifyPayoutConditionInput
  ) {
    deleteAmplifyPayout(input: $input, condition: $condition) {
      id
      payout_id
      order_id
      transaction_type
      projectId
      provider_id
      raw_error_message
      amount
      full_amount
      amount_gate
      groups
      card
      qiwi
      createdAt
      updatedAt
      currency
      payout_status
      user_name
      user_contact_email
      user_phone
      error_code
      error_message
      __typename
    }
  }
`;
export const createAmplifyRefund = /* GraphQL */ `
  mutation CreateAmplifyRefund(
    $input: CreateAmplifyRefundInput!
    $condition: ModelAmplifyRefundConditionInput
  ) {
    createAmplifyRefund(input: $input, condition: $condition) {
      id
      payment_id
      refund_id
      projectId
      amount
      transaction_status
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAmplifyRefund = /* GraphQL */ `
  mutation UpdateAmplifyRefund(
    $input: UpdateAmplifyRefundInput!
    $condition: ModelAmplifyRefundConditionInput
  ) {
    updateAmplifyRefund(input: $input, condition: $condition) {
      id
      payment_id
      refund_id
      projectId
      amount
      transaction_status
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAmplifyRefund = /* GraphQL */ `
  mutation DeleteAmplifyRefund(
    $input: DeleteAmplifyRefundInput!
    $condition: ModelAmplifyRefundConditionInput
  ) {
    deleteAmplifyRefund(input: $input, condition: $condition) {
      id
      payment_id
      refund_id
      projectId
      amount
      transaction_status
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
