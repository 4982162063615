<template>
  <div v-if="!loading">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
  <div v-else class="d-flex vh-100 justify-content-center align-items-center">
    <CSpinner color="dark" />
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import Logger from '@/tools/Logger'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import router from '@/router'
import { Auth } from 'aws-amplify'
// import useCurrentUser from '@/composables/useCurrentUser'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  data() {
    return {
      loading: false,
    }
  },
  async created() {
    try {
      this.loading = true
      const user = await Auth.currentAuthenticatedUser()
      const signedUser = {
        username: user.username,
        groups: user.signInUserSession.accessToken.payload['cognito:groups'] || [],
        login: user.attributes.email.split('@')[0],
        full_login: user.attributes.email,
        offset: user.attributes['custom:timeOffset'],
        time_zone: user.attributes['custom:timeZone'],
      }
      if (user) {
        this.setUser(signedUser)
      } else {
        await router.push(`/login?redirect=/`)
        this.loading = false
        return
      }
    } catch (err) {
      Logger.log(err)
      if (!this.isUser) {
        await router.push(`/login?redirect=/`)
        this.loading = false
        return
      }
    }
    try {
      if (this.getProjects.length === 0) {
        await this.listAmplifyProjects()
        this.loading = false
      } else {
        this.loading = false
      }
    } catch (err) {
      this.loading = false
      Logger.log(err)
    }
  },
  computed: {
    ...mapGetters('projects', ['getProjects']),
    ...mapGetters('auth', ['isUser']),
  },
  methods: {
    ...mapActions('projects', ['listAmplifyProjects']),
    ...mapActions('alert', ['setAlertData']),
    ...mapMutations('auth', ['setUser']),
  },
}
</script>
