import { createStore } from 'vuex'
import projects from './modules/projects'
import sidebar from './modules/sidebar'
import payouts from './modules/payouts'
import payments from './modules/payments'
import refunds from './modules/refunds'
import alert from './modules/alert'
import auth from './modules/auth'
import conversion from './modules/conversion'
import statistics from './modules/statistics'
import callbacks from './modules/callbacks'
import exports from './modules/export'
import profile from './modules/profile'
import wallets from './modules/wallets'
import mfa from './modules/mfa'

export default createStore({
  modules: {
    projects,
    sidebar,
    payouts,
    payments,
    refunds,
    alert,
    auth,
    conversion,
    statistics,
    callbacks,
    exports,
    profile,
    wallets,
    mfa,
  },
})
