const getDefaultState = () => {
  return {
    alert: false,
    alertData: {},
  }
}

const state = getDefaultState()

const getters = {
  isAlert: (state) => state.alert,
  getAlertData: (state) => state.alertData,
}

const actions = {
  setAlertData({ commit }, data) {
    commit('setAlert', true)
    commit('setAlertData', data)
  },
  clearAlertData({ commit }) {
    commit('setAlert', false)
    commit('clearAlertData')
  },
}

const mutations = {
  setAlert: (state, payload) => (state.alert = payload),
  setAlertData: (state, payload) => (state.alertData = payload),
  clearAlertData: (state) => (state.alertData = {}),
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
