import { Auth, Hub } from 'aws-amplify'

const getDefaultState = () => {
  return {
    user: null,
  }
}

const state = getDefaultState()

const actions = {
  login({ commit }, { login, password }) {
    return new Promise((resolve, reject) => {
      Hub.listen('auth', (res) => {
        if ('code' in res.payload.data) {
          reject(res)
        }
      })
      Auth.signIn(login, password)
        .then((user) => {
          commit('setUser', user.username)
          resolve(user)
        })
        .catch((e) => reject(e))
    })
  },

  logout({ dispatch }) {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then((res) => {
          resolve(res)
          localStorage.clear()
          dispatch('clearApplicationData')
        })
        .catch((e) => reject(e))
    })
  },

  clearApplicationData({ commit }) {
    commit('alert/clearState', null, { root: true })
    commit('callbacks/clearState', null, { root: true })
    commit('conversion/clearState', null, { root: true })
    commit('payments/clearState', null, { root: true })
    commit('payouts/clearState', null, { root: true })
    commit('profile/clearState', null, { root: true })
    commit('projects/clearState', null, { root: true })
    commit('refunds/clearState', null, { root: true })
    commit('sidebar/clearState', null, { root: true })
    commit('statistics/clearState', null, { root: true })
    commit('wallets/clearState', null, { root: true })
    commit('auth/clearState', null, { root: true })
  },
}

const mutations = {
  setUser: (state, payload) => {
    state.user = payload
  },

  clearState(state) {
    Object.assign(state, getDefaultState())
  },
}

const getters = {
  isUser: (state) => state.user,
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
