import Logger from '@/tools/Logger'
import { API } from 'aws-amplify'
import { mfaAuth } from '@/graphql/mutations'

const state = {}
const getters = {}
const actions = {
  async generateQr({ rootState, dispatch }) {
    try {
      const res = await API.graphql({
        query: mfaAuth,
        variables: {
          input: {
            email: rootState.auth.user.username,
            action: 'GENERATE_SECRET',
          },
        },
      })
      if (res.data.mfaAuth.success) {
        const payload = JSON.parse(res.data.mfaAuth.payload)
        if (payload && payload.success) {
          return payload.uri
        }
      }
      dispatch('alert/setAlertData', { success: false, message: 'Unexpected Error' }, { root: true })
      return false
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Unexpected Error' }, { root: true })
      return false
    }
  },
  async setupMfa({ rootState, dispatch }, payload) {
    try {
      const res = await API.graphql({
        query: mfaAuth,
        variables: {
          input: {
            email: rootState.auth.user.username,
            code: payload,
            action: 'SETUP',
          },
        },
      })
      if (res.data.mfaAuth.success) {
        const payload = JSON.parse(res.data.mfaAuth.payload)
        if (payload && payload.success && payload.valid.delta === 0) {
          dispatch('alert/setAlertData', { success: true, message: 'Success' }, { root: true })
          return true
        }
      }
      dispatch('alert/setAlertData', { success: false, message: 'Wrong code' }, { root: true })
      return false
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Wrong code' }, { root: true })
      return false
    }
  },
  async checkMfaEnabled({ rootState, dispatch }) {
    try {
      const res = await API.graphql({
        query: mfaAuth,
        variables: {
          input: {
            email: rootState.auth.user.username,
            action: 'CHECK',
          },
        },
      })
      if (res.data.mfaAuth.success) {
        const payload = JSON.parse(res.data.mfaAuth.payload)
        if (payload && payload.success && payload.enabled) {
          return true
        }
      }
      return false
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Unexpected error' }, { root: true })
      return false
    }
  },
  async checkMfaCode({ rootState, dispatch }, payload) {
    try {
      const res = await API.graphql({
        query: mfaAuth,
        variables: {
          input: {
            email: rootState.auth.user.username,
            code: payload,
            action: 'CHECK_CODE',
          },
        },
      })
      if (res.data.mfaAuth.success) {
        const payload = JSON.parse(res.data.mfaAuth.payload)
        if (payload && payload.success && payload.valid.delta === 0) {
          return true
        }
      }
      dispatch('alert/setAlertData', { success: false, message: 'Wrong code' }, { root: true })
      return false
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Wrong code' }, { root: true })
      return false
    }
  },
}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
