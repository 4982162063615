import Logger from '@/tools/Logger'
import { listAmplifyGateways } from '@/graphql/custom-queries'
import { API } from 'aws-amplify'
import { loadProjectSettings, loadUsers } from '@/graphql/queries'
import { addUserToGroup, changeProjectSettings, confirmUser, deleteUser } from '@/graphql/mutations'

const getDefaultState = () => {
  return {
    gateways: [],
    users: [],
    loading: false,
    access: [],
    projectSettings: {},
  }
}

const state = getDefaultState()

const getters = {
  getGateways: (state) => state.gateways,
  getUsers: (state) => state.users,
  isLoading: (state) => state.loading,
  getAccess: (state) => state.access,
  getProjectSettings: (state) => state.projectSettings,
}

const actions = {
  async loadGateways({ commit }) {
    commit('setLoading', true)
    try {
      const res = await API.graphql({
        query: listAmplifyGateways,
      })
      Logger.log(res.data.listAmplifyGateways)
      commit('setGateways', res.data.listAmplifyGateways.items)
      commit('setLoading', false)
    } catch (err) {
      commit('setLoading', false)
      Logger.log(err)
    }
  },
  async loadUsers({ commit }) {
    try {
      commit('setLoading', true)
      const res = await API.graphql({
        query: loadUsers,
      })
      Logger.log(res.data.loadUsers)
      if (res.data.loadUsers.result) {
        commit('setLoading', false)
        commit('setUsers', JSON.parse(res.data.loadUsers.result).fullusers)
        commit('setAccess', JSON.parse(res.data.loadUsers.result).access)
      } else {
        commit('setLoading', false)
        return res.data.loadUsers
      }
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      return {
        message: 'Unexpected error',
        success: false,
      }
    }
  },
  async deleteUser({ commit }, payload) {
    try {
      commit('setLoading', true)
      const res = await API.graphql({
        query: deleteUser,
        variables: {
          input: {
            username: payload,
          },
        },
      })
      Logger.log(res.data.deleteUser)
      if (res.data.deleteUser.success) {
        commit('deleteUser', payload)
      }
      commit('setLoading', false)
      return res.data.deleteUser
    } catch (err) {
      commit('setLoading', false)
      Logger.log(err)
      return {
        message: 'Unexpected error',
        success: false,
      }
    }
  },
  async confirmUser({ commit }, payload) {
    try {
      commit('setLoading', true)
      const res = await API.graphql({
        query: confirmUser,
        variables: {
          input: {
            username: payload,
          },
        },
      })
      Logger.log(res.data.confirmUser)
      if (res.data.confirmUser.success) {
        commit('confirmUser', payload)
      }
      commit('setLoading', false)
      return res.data.confirmUser
    } catch (err) {
      commit('setLoading', false)
      Logger.log(err)
      return {
        message: 'Unexpected error',
        success: false,
      }
    }
  },
  async addUserToGroups({ commit }, payload) {
    try {
      commit('setLoading', true)
      const res = await API.graphql({
        query: addUserToGroup,
        variables: {
          input: {
            username: payload.id,
            groups: payload.groups,
          },
        },
      })
      Logger.log(res.data.addUserToGroup)
      if (res.data.addUserToGroup.success) {
        commit('addUserToGroups', { id: payload.id, groups: payload.groups })
      }
      commit('setLoading', false)
      return res.data.addUserToGroup
    } catch (err) {
      commit('setLoading', false)
      Logger.log(err)
      return {
        message: 'Unexpected error',
        success: false,
      }
    }
  },
  async loadProjectSettings({ commit, dispatch }, payload) {
    try {
      const res = await API.graphql({
        query: loadProjectSettings,
        variables: {
          input: {
            project_id: payload,
          },
        },
      })
      if (res.data.loadProjectSettings.success) {
        commit('setProjectSettings', JSON.parse(res.data.loadProjectSettings.payload))
      } else {
        dispatch('alert/setAlertData', res.data.loadProjectSettings, { root: true })
      }
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Failed to load settings' }, { root: true })
    }
  },
  setProjectSettings({ commit }, payload) {
    commit('setProjectSettings', payload)
  },
  clearProjectSettings({ commit }) {
    commit('clearProjectSettings')
  },
  async updateProjectSettings({ commit, dispatch }, payload) {
    try {
      const res = await API.graphql({
        query: changeProjectSettings,
        variables: {
          input: {
            project_id: payload.project_id,
            payload: JSON.stringify(payload.payload),
          },
        },
      })
      if (!res.data.changeProjectSettings.success) {
        dispatch('alert/setAlertData', res.data.changeProjectSettings, { root: true })
      } else {
        commit('setProjectSettings', payload.payload)
      }
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Failed to change settings' }, { root: true })
    }
  },
}

const mutations = {
  setGateways: (state, payload) => (state.gateways = payload),
  setUsers: (state, payload) => (state.users = payload),
  setLoading: (state, payload) => (state.loading = payload),
  setAccess: (state, payload) => (state.access = payload),
  deleteUser: (state, payload) => {
    const deletedUserIndex = state.users.map((u) => u.id).indexOf(payload)
    state.users.splice(deletedUserIndex, 1)
  },
  confirmUser: (state, payload) => {
    state.users.forEach((u) => {
      if (u.id === payload) {
        u.UserStatus = 'CONFIRMED'
      }
    })
  },
  addUserToGroups: (state, payload) => {
    state.users.forEach((u) => {
      if (u.id === payload.id) {
        u.groups = u.groups.concat(payload.groups)
      }
    })
  },
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
  setProjectSettings: (state, payload) => {
    state.projectSettings = { ...state.projectSettings, ...payload }
  },
  clearProjectSettings: (state) => {
    state.projectSettings = {}
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
