import { API } from 'aws-amplify'
import Logger from '@/tools/Logger'
import { listBalanceOperationByType, listBalanceOperationByDate } from '@/graphql/custom-queries'
import { createBalanceOperation } from '@/graphql/mutations'

const getDefaultState = () => {
  return {
    loading: false,
    nextNextToken: '',
    operations: [],
    exportLoading: false,
  }
}

const state = getDefaultState()

const getters = {
  isLoading: (state) => state.loading,
  isExportLoading: (state) => state.exportLoading,
  getBalanceOperations: (state) => state.operations,
  getNextNextToken: (state) => state.nextNextToken,
}

const actions = {
  async loadProjectBalanceOperations({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true)
      let operationQuery = payload.type === 'ALL' ? listBalanceOperationByDate : listBalanceOperationByType
      let responseQuery = payload.type === 'ALL' ? 'listBalanceOperationByDate' : 'listBalanceOperationByType'
      let response
      if (payload.type === 'ALL') {
        response = await API.graphql({
          query: operationQuery,
          variables: {
            project_id: payload.project_id,
            sortDirection: 'DESC',
            nextToken: payload.nextToken,
            limit: 100,
            idByType: `${payload.project_id}-${payload.type}`,
          },
        })
      } else {
        response = await API.graphql({
          query: operationQuery,
          variables: {
            project_id: payload.project_id,
            idByType: `${payload.project_id}-${payload.type}`,
            sortDirection: 'DESC',
            nextToken: payload.nextToken,
            limit: 100,
          },
        })
      }
      Logger.log(response.data[responseQuery])
      commit('setBalanceOperation', response.data[responseQuery].items)
      commit('setLoading', false)
      commit('setNextNextToken', response.data[responseQuery].nextToken)
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      dispatch('alert/setAlertData', { success: false, message: 'Unexpected Error' }, { root: true })
    }
  },
  async createBalanceOperation({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true)
      if (!payload.comment) {
        commit('setLoading', false)
        dispatch('alert/setAlertData', { success: false, message: 'You have to leave a comment' }, { root: true })
        return
      }
      if (!/^\d+(\.\d+)?$/.test(payload.amount)) {
        commit('setLoading', false)
        dispatch('alert/setAlertData', { success: false, message: 'Wrong amount format, use 10.00 format' }, { root: true })
        return
      }
      const response = await API.graphql({
        query: createBalanceOperation,
        variables: {
          input: payload,
        },
      })
      Logger.log(response.data.createBalanceOperation)
      dispatch('alert/setAlertData', response.data.createBalanceOperation, { root: true })
      commit('setLoading', false)
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      dispatch('alert/setAlertData', { success: false, message: 'Unexpected Error' }, { root: true })
    }
  },
  clearBalanceOperations({ commit }) {
    commit('clearOperations')
  },
  async loadBalanceOperationForCsv({ commit, dispatch }, payload) {
    try {
      commit('setExportLoading', true)
      let nextToken = null
      let operations = []
      const query = payload.operation_type === 'All' ? listBalanceOperationByDate : listBalanceOperationByType
      const queryResponse = payload.operation_type === 'All' ? 'listBalanceOperationByDate' : 'listBalanceOperationByType'
      const primaryKey = {}
      primaryKey.project_id = payload.project_id
      primaryKey.idByType = payload.project_id + '-' + payload.operation_type
      do {
        const res = await API.graphql({
          query,
          variables: {
            ...primaryKey,
            created_at: {
              between: [payload.start_date, payload.end_date],
            },
            sortDirection: 'DESC',
            nextToken,
          },
        })
        if (!res.data) {
          commit('setExportLoading', false)
          dispatch('alert/setAlertData', { success: false, message: 'Unexpected error during export' }, { root: true })
          nextToken = null
          return
        }
        operations = operations.concat(...res.data[queryResponse].items)
        nextToken = res.data[queryResponse].nextToken
      } while (nextToken)
      commit('setExportLoading', false)
      dispatch('alert/setAlertData', { success: true, message: 'Prepare data for CSV' }, { root: true })
      return operations.map((el) => {
        el.project_name = payload.project_name
        return el
      })
    } catch (err) {
      Logger.log(err)
      commit('setExportLoading', false)
      dispatch('alert/setAlertData', { success: false, message: 'Unexpected error occurred' }, { root: true })
    }
  },
}

const mutations = {
  setBalanceOperation: (state, payload) => (state.operations = payload),
  setLoading: (state, payload) => (state.loading = payload),
  setExportLoading: (state, payload) => (state.exportLoading = payload),
  setNextNextToken: (state, payload) => (state.nextNextToken = payload),
  clearOperations: (state) => (state.operations = []),
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
