const getDefaultState = () => {
  return {
    sidebarVisible: true,
  }
}

const state = getDefaultState()

const getters = {
  sidebarVisible: (state) => state.sidebarVisible,
}

const actions = {
  toggleSidebar({ commit }) {
    commit('toggleSidebar')
  },
  setSidebarVisible({ commit }, payload) {
    commit('setSidebarVisible', payload)
  },
}

const mutations = {
  toggleSidebar: (state) => (state.sidebarVisible = !state.sidebarVisible),
  setSidebarVisible: (state, payload) => (state.sidebarVisible = payload),
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
