<template>
  <CFooter class="justify-content-center">
    <div>
      <span>Admin Panel</span>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
