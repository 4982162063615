import { API } from 'aws-amplify'
import { calculateConversion } from '@/graphql/mutations'

const getDefaultState = () => {
  return {
    loading: false,
    settings: {
      offset: '+0000',
      date: new Date().toISOString().split('T')[0],
    },
    noProject: {
      total: 0,
      totalSuccess: 0,
      totalFailure: 0,
      conversion: 0,
      uniqueAll: 0,
      uniqueSuccess: 0,
      uniqueFailure: 0,
      uniqueConversion: 0,
      bothUnique: 0,
      updated: 'never',
      errorsDescription: '',
      noneWlPan: {},
      noneWlPanCount: 0,
      noneWlPanConversion: 0,
    },
  }
}

const state = getDefaultState()

const getters = {
  isLoading: (state) => state.loading,
  getStatistic: (state) => ({ ...state }),
  getSettings: (state) => state.settings,
}

const actions = {
  async loadStatistic({ commit }, data) {
    const result = await API.graphql({
      query: calculateConversion,
      variables: {
        input: {
          transaction_type: data.transactionType,
          data: {
            project: data.project,
            start_date: `${data.date} 00:00:00 UTC${data.offset}`,
            end_date: `${data.date} 23:59:59 UTC${data.offset}`,
          },
        },
      },
    })
    if (result.data.calculateConversion.success === false) {
      commit('clearStatistic', { project: data.project })
      return {
        message: result.data.calculateConversion.message,
        success: result.data.calculateConversion.success,
      }
    }
    result.data.calculateConversion.result.conversion = JSON.parse(result.data.calculateConversion.result.conversion)
    commit('setStatistic', { result: result.data.calculateConversion.result, project: data.project })
  },
  setLoading({ commit }, payload) {
    commit('setLoading', payload)
  },
  setSettings({ commit }, payload) {
    commit('setSettings', payload)
  },
}

const mutations = {
  setStatistic: (state, payload) => {
    state[payload.project] = {
      ...payload.result,
    }
  },
  clearStatistic: (state, payload) => {
    delete state[payload.project]
  },
  setLoading: (state, payload) => (state.loading = payload),
  setSettings: (state, payload) => {
    state.settings = { ...state.settings }
    if ('offset' in payload) state.settings.offset = payload.offset
    if ('date' in payload) state.settings.date = payload.date
  },
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
