import { API } from 'aws-amplify'
import { createAllProvidersReport, deleteAmplifyExportReportHelper, prepareDataForCSV } from '@/graphql/mutations'
import Logger from '@/tools/Logger'
import { getAmplifyExportReportHelper } from '@/graphql/queries'

const state = {}
const getters = {}

const actions = {
  // eslint-disable-next-line
  async loadTransactionsForCsv({ commit, dispatch }, payload) {
    // const project_id = payload.project_id
    const operation_id = Date.now().toString()
    try {
      const res = await API.graphql({
        query: prepareDataForCSV,
        variables: {
          input: {
            transaction_type: payload.type,
            rawProviderData: payload.rawProviderData,
            operation_id: operation_id,
            data: {
              projects: payload.projects,
              project: payload.project_id,
              start_date: payload.start_date,
              end_date: payload.end_date,
              currency: payload.currency,
              include_failed: payload.isIncludedFailed(),
              include_not_final: payload.include_not_final,
              payment_gateway: +payload.payment_gateway,
              provider_export: payload.provider_export,
              headers: JSON.stringify(payload.headers),
              option_id: payload.option_id,
              report: payload.report,
            },
          },
        },
      })
      Logger.log(res.data.prepareDataForCSV)
      dispatch('alert/setAlertData', res.data.prepareDataForCSV, { root: true })
      if (res.data.prepareDataForCSV === null || res.data.prepareDataForCSV.success === false) return false
      if (payload.rawProviderData === true) return res.data.prepareDataForCSV
      if (payload.report === true) return JSON.parse(res.data.prepareDataForCSV.result)

      return res.data.prepareDataForCSV.result
    } catch (err) {
      Logger.log(err)
      if (err.errors && Array.isArray(err.errors) && err.errors[0] && err.errors[0].message === 'Execution timed out.') {
        const result = await new Promise((resolve) => {
          const interval = setInterval(async () => {
            const res = await API.graphql({
              query: getAmplifyExportReportHelper,
              variables: {
                id: operation_id,
              },
            })
            if (res.data.getAmplifyExportReportHelper) {
              if (payload.report === true) {
                resolve(JSON.parse(res.data.getAmplifyExportReportHelper.result))
              } else {
                resolve(res.data.getAmplifyExportReportHelper.result)
              }
              clearInterval(interval)
              resolve()
            }
          }, 5000)
        })

        await API.graphql({
          query: deleteAmplifyExportReportHelper,
          variables: {
            input: {
              id: operation_id,
            },
          },
        })
        return result
      }
      return {
        message: 'Unexpected error occurred',
        success: false,
      }
    }
  },
  // eslint-disable-next-line
  async loadTransactionsForCsvAllMerchants ({commit, dispatch}, payload) {
    try {
      const promises = []
      for (const project of payload.projects) {
        payload.project_id = project
        const func = async () => {
          const res = await dispatch('loadTransactionsForCsv', payload)
          return res
        }
        promises.push(func())
      }
      const res = await Promise.all(promises)
      let transactions = []
      let message
      let success
      res.forEach((result) => {
        transactions = transactions.concat(result.transactions)
        message = result.message
        success = result.success
      })
      transactions = transactions.sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1
        }
        if (a.createdAt < b.createdAt) {
          return -1
        }
        return 0
      })
      return {
        message,
        success,
        transactions,
      }
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Unexpected error occurred',
        success: false,
      }
    }
  },
  // eslint-disable-next-line
  async loadTransactionsForCsvProvider({ commit, dispatch }, payload) {
    try {
      const promises = []
      for (const project of payload.projects) {
        payload.project_id = project
        const func = async () => {
          const res = await dispatch('loadTransactionsForCsv', payload)
          return res
        }
        promises.push(func())
      }
      const res = await Promise.all(promises)
      let transactions = []
      let message
      let success
      res.forEach((result) => {
        transactions = transactions.concat(result.transactions)
        message = result.message
        success = result.success
      })
      transactions = transactions.sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1
        }
        if (a.createdAt < b.createdAt) {
          return -1
        }
        return 0
      })
      return {
        message,
        success,
        transactions,
      }
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Unexpected error occurred',
        success: false,
      }
    }
  },
  async createAllProvidersCSVReport({ dispatch }, payload) {
    const operation_id = Date.now().toString()
    try {
      const result = await API.graphql({
        query: createAllProvidersReport,
        variables: {
          input: {
            operation_id,
            start_date: payload.startDate,
            end_date: payload.endDate,
          },
        },
      })
      dispatch('alert/setAlertData', result.data.createAllProvidersReport, { root: true })
      if (!result.data.createAllProvidersReport.result) {
        dispatch('alert/setAlertData', { message: 'Unexpected error occurred', success: false }, { root: true })
        return
      }
      return result.data.createAllProvidersReport.result
    } catch (err) {
      Logger.log(err)
      if (err.errors && Array.isArray(err.errors) && err.errors[0] && err.errors[0].message === 'Execution timed out.') {
        const result = await new Promise((resolve) => {
          const interval = setInterval(async () => {
            const res = await API.graphql({
              query: getAmplifyExportReportHelper,
              variables: {
                id: operation_id,
              },
            })
            if (res.data.getAmplifyExportReportHelper) {
              resolve(res.data.getAmplifyExportReportHelper.result)
              clearInterval(interval)
            }
          }, 5000)
        })

        await API.graphql({
          query: deleteAmplifyExportReportHelper,
          variables: {
            input: {
              id: operation_id,
            },
          },
        })
        return result
      }
      dispatch('alert/setAlertData', { message: 'Unexpected error occurred', success: false }, { root: true })
    }
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
