<template>
  <CSidebar position="fixed" :unfoldable="false" :visible="sidebarVisible" @visible-change="autoToggle">
    <CSidebarBrand>Admin Panel</CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { AppSidebarNav } from './AppSidebarNav'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },

  computed: {
    ...mapGetters('sidebar', ['sidebarVisible']),
  },
  mounted() {
    if (document.body.clientWidth <= '765') {
      this.setSidebarVisible(false)
    }
  },
  methods: {
    ...mapActions('sidebar', ['setSidebarVisible']),

    autoToggle() {
      if (document.body.clientWidth <= '765') {
        if (!document.querySelectorAll('.sidebar')[0].classList.contains('show')) {
          this.setSidebarVisible(false)
        }
      }
    },
  },
}
</script>
