const getDefaultState = () => {
  return {
    loading: false,
    noProject: {
      conversion: 0,
      updated: 'never',
    },
  }
}

const state = getDefaultState()

const getters = {
  getConversion: (state) => ({ ...state }),
}

const actions = {
  setConversion({ commit }, payload) {
    commit('setConversion', payload)
  },
  setLoading({ commit }, payload) {
    commit('setLoading', payload)
  },
}

const mutations = {
  setConversion: (state, payload) => {
    state[payload.project] = {
      conversion: payload.conversion,
      updated: payload.updated,
    }
  },
  setLoading: (state, payload) => (state.loading = payload),
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
