const a = true
export default a
  ? [
      {
        component: 'CNavItem',
        name: 'Dashboard',
        to: '/dashboard',
      },
      {
        component: 'CNavTitle',
        name: 'Transaction Type',
      },
      {
        component: 'CNavItem',
        name: 'Payments',
        to: '/payments',
        icon: 'cibCcMastercard',
      },
      {
        component: 'CNavItem',
        name: 'Payouts',
        to: '/payouts',
        icon: 'cibCcVisa',
      },
      {
        component: 'CNavItem',
        name: 'Refunds',
        to: '/refunds',
        icon: 'cilXCircle',
      },
      {
        component: 'CNavTitle',
        name: 'Exporting',
      },
      {
        component: 'CNavItem',
        name: 'Export transactions',
        to: '/export',
        icon: 'cilMagnifyingGlass',
      },
      {
        component: 'CNavItem',
        name: 'Report',
        to: '/report',
        icon: 'cilMagnifyingGlass',
      },
      {
        component: 'CNavTitle',
        name: 'Wallets',
      },
      {
        component: 'CNavItem',
        name: 'Wallets',
        to: '/wallets',
        icon: 'cilWallet',
      },
      {
        component: 'CNavTitle',
        name: 'Other',
      },
      {
        component: 'CNavItem',
        name: 'FAQ',
        to: '/faq',
        icon: 'cilSpeech',
      },
      {
        component: 'CNavItem',
        name: 'Support',
        to: '/support',
        icon: 'cilCommentSquare',
      },
    ]
  : [
      {
        component: 'CNavItem',
        name: 'Dashboard',
        to: '/dashboard',
      },
      {
        component: 'CNavTitle',
        name: 'Transaction Type',
      },
      {
        component: 'CNavItem',
        name: 'Payments',
        to: '/payments',
        icon: 'cibCcMastercard',
      },
      {
        component: 'CNavItem',
        name: 'Refunds',
        to: '/refunds',
        icon: 'cilXCircle',
      },
      {
        component: 'CNavTitle',
        name: 'Exporting',
      },
      {
        component: 'CNavItem',
        name: 'Export transactions',
        to: '/export',
        icon: 'cilMagnifyingGlass',
      },
      {
        component: 'CNavItem',
        name: 'Report',
        to: '/report',
        icon: 'cilMagnifyingGlass',
      },
      {
        component: 'CNavTitle',
        name: 'Wallets',
      },
      {
        component: 'CNavItem',
        name: 'Wallets',
        to: '/wallets',
        icon: 'cilLayers',
      },
      {
        component: 'CNavTitle',
        name: 'Other',
      },
      {
        component: 'CNavItem',
        name: 'FAQ',
        to: '/faq',
        icon: 'cilSpeech',
      },
      {
        component: 'CNavItem',
        name: 'Support',
        to: '/support',
        icon: 'cilCommentSquare',
      },
    ]
